import { Component } from '@angular/core';
import { filter, first, fromEvent, map } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public done: boolean = false;
  public message: string;
  public title: string;

  constructor() {
    fromEvent<MessageEvent>(window, 'message')
      .pipe(
        filter(message => !!message?.data && typeof message?.data === 'string'),
        map(message => JSON.parse(message.data)),
        filter(data => data.type === 'interactionsMessage'),
        first()
      )
      .subscribe((data) => {
        this.title = data.title;
        this.message = data?.message;
        this.done = false;
      });
  }

  public routeLoaded(): void {
    this.done = true;
  }
}
