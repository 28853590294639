import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.scss']
})
export class CustomMessageComponent {
  @Input() title: string;
  @Input() message: string;
}
